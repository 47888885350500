import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  HiPlay,
  HiCommandLine,
  HiSquare2Stack,
  HiSparkles,
  HiLanguage,
  HiCpuChip,
  HiKey
} from 'react-icons/hi2';

const EasterEggContext = createContext();

const TIERS = {
  EXPLORER: { name: 'Explorer', multiplier: 1, minPoints: 0 },
  DISCOVERER: { name: 'Discoverer', multiplier: 1.5, minPoints: 50 },
  MASTER: { name: 'Master', multiplier: 2, minPoints: 100 }
};

// Total possible points: 100
export const easterEggs = {
  PROFILE_VIDEO: {
    id: 'PROFILE_VIDEO',
    name: 'Curious explorer',
    description: 'Found the hidden video',
    points: 15,
    tier: TIERS.EXPLORER,
    icon: HiPlay
  },
  FONT_SWITCH: {
    id: 'FONT_SWITCH',
    name: 'Typography enthusiast',
    description: 'Experimented with different fonts',
    points: 10,
    tier: TIERS.EXPLORER,
    icon: HiLanguage
  },
  THEME_KEYBOARD: {
    id: 'THEME_KEYBOARD',
    name: 'Keyboard warrior',
    description: 'Used the "L" shortcut to toggle theme',
    points: 15,
    tier: TIERS.EXPLORER,
    icon: HiKey
  },
  COMMAND_PALETTE: {
    id: 'COMMAND_PALETTE',
    name: 'Power user',
    description: 'Used ⌘K to open command palette',
    points: 20,
    tier: TIERS.DISCOVERER,
    icon: HiCommandLine
  },
  SOCIAL_EXPANDER: {
    id: 'SOCIAL_EXPANDER',
    name: 'Social explorer',
    description: 'Found the hidden social links',
    points: 15,
    tier: TIERS.EXPLORER,
    icon: HiSquare2Stack
  },
  VERSION_SHIMMER: {
    id: 'VERSION_SHIMMER',
    name: 'Detail observer',
    description: 'Found the version number shimmer',
    points: 15,
    tier: TIERS.EXPLORER,
    icon: HiSparkles
  },
  AI_CHAT: {
    id: 'AI_CHAT',
    name: 'AI enthusiast',
    description: 'Had a chat with AI assistant',
    points: 20,
    tier: TIERS.DISCOVERER,
    icon: HiCpuChip
  }
};

const TOTAL_POSSIBLE_POINTS = Object.values(easterEggs).reduce(
  (total, egg) => total + egg.points, 
  0
);

// Add a helper function to check if an egg can be discovered
const canDiscoverEgg = (eggId, discoveredEggs) => {
  return easterEggs[eggId] && !discoveredEggs[eggId];
};

export function EasterEggProvider({ children }) {
  const [discoveredEggs, setDiscoveredEggs] = useState(() => {
    const saved = localStorage.getItem('discoveredEggs');
    return saved ? JSON.parse(saved) : {};
  });
  
  const [totalPoints, setTotalPoints] = useState(() => {
    return Object.values(discoveredEggs).reduce((sum, egg) => sum + egg.points, 0);
  });

  const [currentTier, setCurrentTier] = useState(TIERS.EXPLORER);
  const [lastActionTime, setLastActionTime] = useState(null);

  const discoverEgg = (eggId) => {
    // Add a guard clause to prevent multiple triggers
    if (!canDiscoverEgg(eggId, discoveredEggs)) {
      return false;
    }

    const currentTime = new Date();
    const newDiscoveredEggs = {
      ...discoveredEggs,
      [eggId]: {
        timestamp: currentTime.toISOString(),
        points: easterEggs[eggId].points
      }
    };

    setDiscoveredEggs(newDiscoveredEggs);
    setTotalPoints(prev => prev + easterEggs[eggId].points);
    setLastActionTime(Date.now());

    // Update tier based on new total
    const newTotal = totalPoints + easterEggs[eggId].points;
    if (newTotal >= TIERS.MASTER.minPoints) setCurrentTier(TIERS.MASTER);
    else if (newTotal >= TIERS.DISCOVERER.minPoints) setCurrentTier(TIERS.DISCOVERER);

    // Show achievement notification
    setCurrentAchievement(easterEggs[eggId]);
    setShowAchievement(true);

    return true;
  };

  // Progress calculation
  const progress = {
    discovered: Object.keys(discoveredEggs).length,
    total: Object.keys(easterEggs).length,
    percentage: Math.round((Object.keys(discoveredEggs).length / Object.keys(easterEggs).length) * 100),
    possiblePoints: TOTAL_POSSIBLE_POINTS,
    earnedPoints: totalPoints,
    earnedPercentage: Math.round((totalPoints / TOTAL_POSSIBLE_POINTS) * 100)
  };

  // Persist discoveries
  useEffect(() => {
    localStorage.setItem('discoveredEggs', JSON.stringify(discoveredEggs));
  }, [discoveredEggs]);

  // Add notification state
  const [showAchievement, setShowAchievement] = useState(false);
  const [currentAchievement, setCurrentAchievement] = useState(null);

  const resetEasterEggs = () => {
    setDiscoveredEggs({});
    setTotalPoints(0);
    setCurrentTier(TIERS.EXPLORER);
    localStorage.removeItem('discoveredEggs');
  };

  return (
    <EasterEggContext.Provider value={{ 
      discoveredEggs, 
      discoverEgg, 
      totalPoints,
      currentTier,
      progress,
      TOTAL_POSSIBLE_POINTS,
      showAchievement,
      setShowAchievement,
      currentAchievement,
      resetEasterEggs
    }}>
      {children}
    </EasterEggContext.Provider>
  );
}

export const useEasterEggs = () => useContext(EasterEggContext);